const config = {
  gMapsKey: "AIzaSyBga95iwpkCy_iP7RND9U-Ta79M5WGQ6bE",
};

export const firebaseConfig = {
  apiKey: "AIzaSyD6Imp9EtR9cCaZp89z5WzIbue8-1DMIz8",
  authDomain: "homepage-49923.firebaseapp.com",
  databaseURL:
    "https://homepage-49923-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "homepage-49923",
  storageBucket: "homepage-49923.appspot.com",
  messagingSenderId: "981224231748",
  appId: "1:981224231748:web:6bc3d49d6793f6f2bb3d11",
};

export default config;
