export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
};

export const getUserIdFromLocalStorage = () => {
  return localStorage.getItem("userId") || null;
};

export const addUserIdToLocalStorage = () => {
  const userId = uuidv4();

  localStorage.setItem("userId", userId);

  return userId;
};

export const getOrderIdFromLocalStorage = () => {
  return localStorage.getItem("orderId") || null;
};

export const addOrderIdToLocalStorage = () => {
  const orderId = uuidv4();

  localStorage.setItem("orderId", orderId);

  return orderId;
};
