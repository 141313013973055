import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "./../header/header";
import FunBlock from "./../fun-block/fun-block";
import Translate from "../translate/translate";
import { PhotoMap } from "../../pages/photo-map";
import PhotoSwipeWrapper from "../photoswipe/photoswipe-wrapper";
import { ShareTable } from "../share-table/share-table";
import { uuidv4 } from "../share-table/utils";
import { useNavigate, useLocation } from "react-router-dom";

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleCreatingTable = () => {
    navigate(location.pathname + uuidv4());
  };

  return (
    <>
      <Header />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <h1 style={{ textAlign: "center" }}>
                There is nothing interesting
              </h1>
            </>
          }
        />
        <Route
          path="/nothing-special"
          element={
            <>
              <FunBlock />
              <Translate />
            </>
          }
        />
        <Route
          path="/novosibirsk"
          element={
            <>
              <PhotoMap />
              <PhotoSwipeWrapper />
            </>
          }
        />
        <Route
          path="/share-table"
          element={
            <>
              <div>
                <button type="button" onClick={handleCreatingTable}>
                  Create a table
                </button>
              </div>
            </>
          }
        />
        <Route
          path="/share-table/:id"
          element={
            <>
              <ShareTable />
            </>
          }
        />
        <Route
          path="*"
          element={
            <>
              <h1 style={{ textAlign: "center" }}>404</h1>
            </>
          }
        />
      </Routes>
    </>
  );
};

export default App;
